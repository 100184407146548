import React from 'react';
import './Navbar.scss';
import logo from './../../assets/logo-2.jpg';
import {Link} from 'react-router-dom';
// import icon from '../../assets/banner/icons/Calling.png';

const Navbar = () => {

    const navbarItems = [
        // {
        //     name: 'Trung tâm bảo hành Nhất Tín Labo',
        //     path: '/',
        // },
        // {
        //     name: 'About Us',
        //     path: '/about',
        // },
        // {
        //     name: 'Bao hanh',
        //     path: '/baohanh',
        // },
        // {
        //     name: 'Blogs',
        //     path: '/blogs',
        // },
        // {
        //     name: 'Contact Us',
        //     path: '/contact',
        // }
    ];

    return (
        <div className='main-nav'>
            <div className="container">
                <nav className="navbar navbar-expand-lg">
                    <div className="container-fluid">
                        {/* Logo */}
                        <Link className="navbar-brand" to="/">
                            <img style={{height:'48px'}} src={logo} alt="logo" />
                        </Link>
                        <h4>Nhất Tín Labo</h4>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            {/* Navbar Link */}
                            <ul className="navbar-nav m-auto mb-2 mb-lg-0">
                               { 
                                navbarItems.map (navSingle =>
                                    <li className="nav-item">
                                        <Link className="nav-link" to={navSingle.path}>{navSingle.name}</Link>
                                    </li>
                                    ) 
                                }
                                {/* <li className="nav-item">
                                    <h4>Trung tâm bảo hành Nhất Tín Labo</h4>
                                </li> */}
                            </ul>
                            {/* <h4>Trung tâm bảo hành Nhất Tín Labo</h4> */}
                            
                            {/* Navbar Button */}
                            {/* <div className="theme-btn">
                                <Link>Book appointment</Link>
                            </div> */}

                        </div>
                    </div>
                </nav>
            </div>
        </div>
    );
};

export default Navbar;